import { schema } from 'normalizr';

export const AnswerTime = new schema.Entity('answerTime');
export const QuestionnaireResult = new schema.Entity('questionnaireResult');
export const QuestionnaireSubResult = new schema.Entity('questionnaireSubResult');
export const Questionnaire = new schema.Entity('questionnaire');
export const Question = new schema.Entity('question');
export const QuestionAnswer = new schema.Entity('questionAnswer');
export const QuestionChoice = new schema.Entity('questionChoice');
export const QuestionValueHeader = new schema.Entity('questionValueHeader');
export const QuestionGroup = new schema.Entity('questionGroup');
export const User = new schema.Entity('user');
export const AnonymizedUserData = new schema.Entity('anonymizedUserData');
export const Organization = new schema.Entity('organization');
export const OrganizationInvite = new schema.Entity('organizationInvite');
export const OrganizationUser = new schema.Entity('organizationUser');
export const OrganizationQuestionnaire = new schema.Entity('organizationQuestionnaire');
export const ServiceOrganizationUser = new schema.Entity('serviceOrganizationUser');
export const ServiceInvite = new schema.Entity('serviceInvite');
export const ServiceQuestionnaire = new schema.Entity('serviceQuestionnaire');
export const ServiceQuestionnaireBaselineInterval = new schema.Entity('serviceQuestionnaireBaselineInterval');
export const ServiceQuestionnaireFixedAnswerRequest = new schema.Entity('serviceQuestionnaireFixedAnswerRequest');
export const ServiceUser = new schema.Entity('serviceUser');
export const ServiceUserQuestionnaire = new schema.Entity('serviceUserQuestionnaire');
export const Service = new schema.Entity('service');
export const Measurement = new schema.Entity('measurement');
export const MeasurementTypeDetail = new schema.Entity('measurementTypeDetail');
export const ReportPlot = new schema.Entity('reportPlot');
export const ReportDataObject = new schema.Entity('reportDataObject');
export const UserConsent = new schema.Entity('userConsent');
export const Consent = new schema.Entity('consent');
export const UserMessage = new schema.Entity('userMessage');
export const ServiceUserNote = new schema.Entity('serviceUserNote');
export const ServiceCollaborator = new schema.Entity('serviceCollaborator');

AnswerTime.define({
  serviceUserQuestionnaire: ServiceUserQuestionnaire,
  questionnaireResult: QuestionnaireResult,
  questionnaire: Questionnaire,
  user: User,
  professionalUser: User,
  serviceUser: ServiceUser,
  questionAnswers: [QuestionAnswer]
});

Service.define({
  organization: Organization,
  serviceUsers: [ServiceUser],
  serviceOrganizationUsers: [ServiceOrganizationUser],
  serviceQuestionnaires: [ServiceQuestionnaire],
  serviceInvites: [ServiceInvite]
});

ServiceInvite.define({
  service: Service,
  user: User
});

ServiceUser.define({
  service: Service,
  user: User,
  anonymizedUserData: AnonymizedUserData,
  measurements: [Measurement],
  serviceUserQuestionnaires: [ServiceUserQuestionnaire],
  answerTimes: [AnswerTime]
});

ServiceUserNote.define({});

ServiceOrganizationUser.define({
  organization: Organization,
  service: Service,
  user: User
});

ServiceQuestionnaire.define({
  service: Service,
  questionnaire: Questionnaire,
  serviceUserQuestionnaires: [ServiceUserQuestionnaire],
  serviceQuestionnaireBaselineIntervals: [ServiceQuestionnaireBaselineInterval],
  serviceQuestionnaireFixedAnswerRequests: [ServiceQuestionnaireFixedAnswerRequest]
});

ServiceQuestionnaireBaselineInterval.define({
  serviceQuestionnaire: ServiceQuestionnaire,
  parentDependency: ServiceQuestionnaire
});

ServiceQuestionnaireFixedAnswerRequest.define({
  serviceQuestionnaire: ServiceQuestionnaire,
  parentDependency: ServiceQuestionnaire
});

ServiceCollaborator.define({
  service: Service
});

ServiceUserQuestionnaire.define({
  serviceQuestionnaire: ServiceQuestionnaire,
  serviceUser: ServiceUser,
  questionAnswers: [QuestionAnswer],
  answerTimes: [AnswerTime],
  parentDependency: ServiceQuestionnaire
});

User.define({
  serviceOrganizationUsers: [ServiceOrganizationUser],
  organizationUsers: [OrganizationUser],
  userMessages: [UserMessage]
});

UserMessage.define({
  user: User,
  organizationUser: OrganizationUser,
  service: Service
});

QuestionnaireResult.define({
  questionnaire: Questionnaire,
  serviceQuestionnaire: ServiceQuestionnaire,
  questionAnswers: [QuestionAnswer],
  questionnaireSubResults: [QuestionnaireSubResult]
});

QuestionnaireSubResult.define({
  questionnaireResult: QuestionnaireResult
});

Question.define({
  questionGroup: QuestionGroup,
  questionChoices: [QuestionChoice],
  questionValueHeaders: [QuestionValueHeader]
});

QuestionChoice.define({
  question: Question,
  questionAnswers: [QuestionAnswer]
});

QuestionAnswer.define({
  answerTime: AnswerTime,
  questionChoice: QuestionChoice,
  questionnaireResult: QuestionnaireResult
});

QuestionChoice.define({
  question: Question,
  questionAnswers: [QuestionAnswer]
});

QuestionGroup.define({
  questionnaire: Questionnaire,
  questions: [Question],
  questionGroupValueHeaders: [QuestionValueHeader]
});

Questionnaire.define({
  organization: Organization,
  questionGroups: [QuestionGroup],
  organizationQuestionnaires: [OrganizationQuestionnaire]
});

Organization.define({
  services: [Service],
  organizationUsers: [OrganizationUser],
  questionnaires: [Questionnaire]
});

OrganizationUser.define({
  organization: Organization,
  user: User
});

OrganizationInvite.define({
  organization: Organization,
  user: User
});

OrganizationQuestionnaire.define({
  organization: Organization,
  questionnaire: Questionnaire
});

Measurement.define({
  eventUser: ServiceUser,
  measurementTypeDetail: MeasurementTypeDetail
});

MeasurementTypeDetail.define({
  measurements: [Measurement]
});

UserConsent.define({
  consent: Consent,
  user: User
});
